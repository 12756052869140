import type { SxProps } from '@mui/material';
import { Box, Stack, Typography } from '@mui/material';
import type { FC, PropsWithChildren } from 'react';

export const InfoFieldStack: FC<PropsWithChildren> = ({ children }) => (
  <Stack
    direction="row"
    spacing={3}
    sx={{
      display: 'flex',
      alignItems: 'start',
      marginBottom: -1,
      paddingBottom: 1,
      width: '100%',
      overflowY: 'clip',
      overflowX: 'auto',
    }}
  >
    {children}
  </Stack>
);

export const InfoFieldView: FC<
  PropsWithChildren<{ label: string; sx?: SxProps }>
> = ({ children, label, sx }) => (
  <Stack spacing={0} sx={{ alignItems: 'start', ...sx }}>
    <Typography color="text.secondary" fontSize="0.75rem" whiteSpace="nowrap">
      {label}
    </Typography>
    <Typography
      fontSize="1.1rem"
      lineHeight={1}
      fontWeight={500}
      whiteSpace="nowrap"
    >
      {children}
    </Typography>
  </Stack>
);

export const InfoFieldNonTextView: FC<
  PropsWithChildren<{ label: string; sx?: SxProps }>
> = ({ children, label, sx }) => (
  <Stack spacing={0} sx={{ alignItems: 'start', ...sx }}>
    <Typography color="text.secondary" fontSize="0.75rem" whiteSpace="nowrap">
      {label}
    </Typography>
    <Box fontSize="1.1rem" lineHeight={1} fontWeight={500} whiteSpace="nowrap">
      {children}
    </Box>
  </Stack>
);
